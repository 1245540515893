import { Injectable } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { Subscription, timer } from 'rxjs'
import { take } from 'rxjs/operators'

interface BlinkOptions {
  count?: number
  intervalDuration?: number
  delay?: number
}

const DEFAULT_OPTIONS = {
  count: 3,
  intervalDuration: 1000,
  delay: 0,
}

@Injectable({
  providedIn: 'root',
})
export class TitleService {
  private currentBlink?: Subscription

  public get title(): string {
    return this._title
  }

  public set title(value: string) {
    this.cancelBlink()
    this.titleService.setTitle(value)
    this._title = value
  }

  private _title = ''

  public constructor(private titleService: Title) {
    this.title = titleService.getTitle()
  }

  public blink(title: string, options: BlinkOptions = DEFAULT_OPTIONS) {
    const safeOptions = Object.assign({ ...DEFAULT_OPTIONS }, options)

    this.cancelBlink()
    const oldTitle = this.title

    this.currentBlink = timer(safeOptions.delay, safeOptions.intervalDuration)
      .pipe(take(safeOptions.count * 2))
      .subscribe({
        next: (i) => this.titleService.setTitle(i % 2 === 0 ? title : oldTitle),
        complete: () => this.cancelBlink(),
      })
  }

  private cancelBlink(): void {
    if (this.currentBlink) {
      this.currentBlink.unsubscribe()
      this.titleService.setTitle(this.title)
    }
  }
}
