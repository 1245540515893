import { Component, OnInit } from '@angular/core'
import { io } from 'socket.io-client'
import { SocketService } from './socket.service'
import { Observable, repeat, retry, switchMap } from 'rxjs'
import { AuthService } from '../../user/infrastructure/auth.service'
import { environment } from '../../../environments/environment'
import { Socket } from 'socket.io-client/build/esm/socket'

@Component({
  selector: 'app-socket',
  template: '',
})
export class SocketComponent implements OnInit {
  public constructor(private authService: AuthService, private socketService: SocketService) {}

  public ngOnInit(): void {
    this.connect()
  }

  public connect() {
    this.createIo().subscribe((io) =>
      io.onAny((name: string, ...args: (object | string | number)[]) =>
        this.socketService.events$.next({ name, args }),
      ),
    )
  }

  private createIo() {
    return this.authService.getValidAuthToken(true).pipe(
      switchMap((authToken) =>
        new Observable<Socket>((observer) => {
          const socket = io(environment.socketUrl, { auth: { token: authToken } })

          socket.on('connect_error', () =>
            observer.error(new Error('Could not connect to the socket')),
          )

          socket.on('disconnect', () => observer.complete())

          observer.next(socket)

          return () => socket.disconnect()
        }).pipe(retry(3)),
      ),
      repeat(),
    )
  }
}
