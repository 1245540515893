<div
  [ngClass]="{
    room: true,
    room__active: isSelected
  }"
  (click)="onClick()"
>
  <img class="room-image" [src]="room.imagePath ? resolveUrl(room.imagePath) : defaultRoomImage" />
  <span>{{ room.text }}</span>
</div>
