<app-message-presentation
  [message]="message"
  [shouldShowTime]="shouldShowTime()"
  [shouldConnectToPreviousMessage]="shouldConnectToPreviousMessage()"
  [shouldConnectToNextMessage]="shouldConnectToNextMessage()"
  [shouldDisplayUsernameHeader]="shouldDisplayUsernameHeader()"
  (attachmentLoaded)="onAttachmentLoaded()"
  (attachmentClicked)="onAttachmentClicked($event)"
  (reactActionClicked)="onReactActionClick($event)"
  (contentTapHeld)="onContentTapHold()"
>
</app-message-presentation>
