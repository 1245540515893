import { Component, OnInit } from '@angular/core'
import { AuthService } from '../../../user/infrastructure/auth.service'
import { Router } from '@angular/router'
import { TitleService } from '../../../app/infrastructure/title.service'
import { HttpErrorResponse } from '@angular/common/http'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public error?: ResponseError
  public email = ''
  public password = ''

  public constructor(
    private authService: AuthService,
    private titleService: TitleService,
    private router: Router,
  ) {}

  public ngOnInit(): void {
    this.titleService.title = 'Login'
    if (this.authService.logoutReason) {
      this.error = { code: 'logout', message: this.authService.logoutReason }
      this.authService.clearLogoutReason()
    }
  }

  public onForgotPasswordClick(e: MouseEvent) {
    alert('Well aint dys a bad day fo ya ...')
    e.preventDefault()
  }

  public onLoginFormSubmit(e: Event) {
    e.preventDefault()

    this.error = undefined
    this.authService.login(this.email, this.password).subscribe({
      next: () => this.router.navigate(['/']),
      error: (e: HttpErrorResponse) => (this.error = e.error as ResponseError),
    })
  }
}

interface ResponseError {
  code: string
  message: string
  data?: object
}
