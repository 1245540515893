import {
  AfterViewChecked,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { BehaviorSubject, filter, forkJoin, fromEvent, Subscription } from 'rxjs'
import { defaultIfEmpty, map, switchMap } from 'rxjs/operators'
import { v4 as uuid } from 'uuid'

import { USER_DUMMY_ME, USER_DUMMY_OTHER } from '../../../user/domain/user/USERS'
import { Message, PrimitiveMessage, primitiveMessageToMessage } from '../../domain/message/message'
import { User } from '../../../user/domain/user/user'
import { UserService } from '../../../user/infrastructure/user.service'
import { TitleService } from '../../../app/infrastructure/title.service'
import { FileService } from '../../../file/infrastructure/file.service'
import { Room } from '../../domain/room/room'
import { ROOMS } from '../../domain/room/ROOMS'
import { SocketService } from '../../../core/socket/socket.service'
import { UserMessage } from '../../domain/message/user-message'
import {
  CACHED_MESSAGE_SERVICE,
  CreateMessageRequestBodyAttachment,
  MessageService,
} from '../../domain/message/message.service'
import { HTTP_ROOM_SERVICE, RoomService } from '../../domain/room/room.service'
import { isCachedMessage } from '../cached.message'
import { UserReact } from '../../domain/message/user-react'
import { REACTS } from '../../domain/message/react'

interface Attachment {
  id: string
  loaded: boolean
  file: File
  dataUrl?: string
}

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit, AfterViewChecked, OnDestroy {
  private readonly title = 'Chat'
  private readonly dummyUuid = '00000000-0000-0000-0000-000000000000'

  public readonly MAX_LENGTH = 500
  public me = USER_DUMMY_ME
  public selectedRoom: Room = ROOMS[0]
  public rooms: Room[] = ROOMS
  public messages: UserMessage[] = []
  public message = ''
  public attachments: Attachment[] = []
  public loaded = false
  private users: User[] = []

  private interval?: number
  @ViewChild('chatScroll') private chatScrollElement!: ElementRef<HTMLDivElement>
  private _autoScrollDown = true
  private preserveScrollHeight = 0
  private preserveScrollTop = 0
  private oldScrollHeight = 0
  private messagesLoaded = true
  private noMoreMessagesToLoad = false

  private getMessagesSubscription?: Subscription

  private autoScrollDownScheduled = true

  private preserveScrollScheduled = false

  private isActiveTab: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)

  public latestMessagesLoaded(): boolean {
    return this.hasMessages() && !this.isLastMessageDummy() && !this.isLastMessageCached()
  }

  public isLastMessageCached(): boolean {
    return this.hasMessages()
      ? isCachedMessage(this.messages[this.messages.length - 1].message)
      : false
  }

  public isLastMessageDummy(): boolean {
    return this.hasMessages()
      ? this.messages[this.messages.length - 1].message.id === this.dummyUuid
      : false
  }

  public hasMessages(): boolean {
    return this.messages.length > 0
  }

  public get isMessageLengthValid(): boolean {
    return this.message.length <= this.MAX_LENGTH
  }

  public get createMessageEnabled(): boolean {
    return this.isMessageLengthValid
  }

  public get autoScrollDown(): boolean {
    return this._autoScrollDown
  }

  private set autoScrollDown(value: boolean) {
    this._autoScrollDown = value
  }

  public constructor(
    private userService: UserService,
    @Inject(CACHED_MESSAGE_SERVICE) private messageService: MessageService,
    @Inject(HTTP_ROOM_SERVICE) private roomService: RoomService,
    private fileService: FileService,
    private titleService: TitleService,
    private socketService: SocketService,
  ) {
    this.generateDummyUsers()
    this.generateDummyMessages()
    this.scheduleScrollToBottom()
  }

  public ngOnInit(): void {
    forkJoin([
      this.userService.getMe(),
      this.userService.getUsers(),
      this.roomService.getRooms(),
    ]).subscribe(([me, users, rooms]) => {
      this.me = me
      this.users = users
      this.rooms = rooms
    })

    this.socketService.events$
      .pipe(
        filter(({ name }) => name === 'room:message-sent'),
        map(({ args: [roomId, primitiveMessage] }) => ({
          roomId,
          message: primitiveMessageToMessage(primitiveMessage as PrimitiveMessage),
        })),
      )
      .subscribe(({ roomId, message }) => {
        if (this.selectedRoom.id === roomId) {
          this.addMessage(message)
        }
      })

    this.socketService.events$
      .pipe(
        filter(({ name }) => name === 'message:reacted'),
        map(({ args: [messageId, userReact] }) => ({
          messageId,
          userReact: userReact as UserReact,
        })),
      )
      .subscribe(({ messageId, userReact }) => {
        this.messages = this.messages.map(({ message, user, isMy }) => {
          if (message.id === messageId) {
            const react = REACTS.find((react) => react.id === userReact.reactId)
            if (react) {
              message.react(userReact.userId, react)
            } else {
              // TODO: Do something
            }
          }
          return { message, user, isMy }
        })
      })

    this.socketService.events$
      .pipe(
        filter(({ name }) => name === 'message:unreacted'),
        map(({ args: [messageId, userId] }) => ({
          messageId,
          userId,
        })),
      )
      .subscribe(({ messageId, userId }) => {
        this.messages.map(({ message, user }) => {
          if (message.id === messageId) {
            message.unreact(userId as string)
          }
          return { message, user }
        })
      })

    fromEvent(document, 'visibilitychange').subscribe(() =>
      this.isActiveTab.next(document.visibilityState === 'visible'),
    )

    this.isActiveTab.subscribe((isActive) => {
      if (isActive) {
        this.titleService.title = this.title

        if (this.loaded) {
          this.reloadMessages(this.selectedRoom)
        }
      }
    })
  }

  public ngAfterViewChecked(): void {
    if (this.oldScrollHeight !== this.chatScrollElement.nativeElement.scrollHeight) {
      this.onScrollHeightChange(
        this.oldScrollHeight,
        this.chatScrollElement.nativeElement.scrollHeight,
      )
      this.oldScrollHeight = this.chatScrollElement.nativeElement.scrollHeight
    }
    this.autoScrollDownScheduled = false
  }

  public ngOnDestroy(): void {
    clearInterval(this.interval)
  }

  public onSelectedRoomChange(room: Room) {
    if (room.id !== ROOMS[0].id && room.id !== this.selectedRoom.id) {
      if (this.loaded) {
        this.generateDummyMessages()
      }
      this.reloadMessages(room)
    }
    this.selectedRoom = room
  }

  public onSendButtonClick() {
    this.sendMessage()
  }

  public onCreateMessageFieldKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter' && !event.shiftKey) {
      this.sendMessage()
      event.preventDefault()
      event.stopPropagation()
    }
  }

  public onCreateMessageFieldPaste(event: ClipboardEvent) {
    if (!event.clipboardData || event.clipboardData.items.length === 0) {
      return
    }

    const items = event.clipboardData.items
    for (let i = 0; i < items.length; i++) {
      const item = items[i]
      if (!item.type.includes('image')) {
        continue
      }

      const file = item.getAsFile()
      if (!file) {
        continue
      }

      this.addAttachment(file)
      event.preventDefault()
    }
  }

  public onChatScroll() {
    const element = this.chatScrollElement.nativeElement
    if (element.scrollTop < 50) {
      this.loadMoreMessages()
    }

    this.autoScrollDown = element.scrollHeight - element.scrollTop <= element.clientHeight
  }

  public onScrollDownButtonClick() {
    this.scrollToBottom()
  }

  public onFileChange(e: Event) {
    if (e.target) {
      Array.from((e.target as HTMLInputElement).files || []).map((file) => this.addAttachment(file))
    }
  }

  public removeAttachment(id: string) {
    this.attachments = this.attachments.filter((attachment: Attachment) => attachment.id !== id)
  }

  public onAttachmentLoaded() {
    if (this.autoScrollDown) {
      this.scheduleScrollToBottom()
    }
  }

  private sendMessage(): void {
    if (!this.createMessageEnabled || (!this.message && this.attachments.length === 0)) {
      return
    }

    const scrollDown = this.autoScrollDown

    const attachments = this.attachments
    this.attachments = []
    forkJoin(
      attachments.map((attachment) =>
        this.fileService.uploadFile(this.selectedRoom.id, attachment.file).pipe(
          map(
            (file) =>
              ({
                id: file.id,
              } as CreateMessageRequestBodyAttachment),
          ),
        ),
      ),
    )
      .pipe(
        defaultIfEmpty([] as CreateMessageRequestBodyAttachment[]),
        switchMap((attachments) =>
          this.messageService.sendMessage(this.selectedRoom.id, this.message, attachments),
        ),
      )
      .subscribe(() => (this.message = ''))

    if (scrollDown) {
      this.scheduleScrollToBottom()
    }
  }

  private reloadMessages(room: Room) {
    this.loaded = false
    this.noMoreMessagesToLoad = false

    if (this.getMessagesSubscription) {
      this.getMessagesSubscription.unsubscribe()
    }

    this.getMessagesSubscription = this.messageService.getMessages(room.id).subscribe({
      next: (messages) => {
        if (messages.length !== 30) {
          this.noMoreMessagesToLoad = true
        }

        this.messages = []
        this.refreshMessages(messages)
        this.scheduleScrollToBottom()
        this.loaded = true
      },
      complete: () => {
        this.getMessagesSubscription = undefined
      },
    })
  }

  private loadMoreMessages() {
    if (!this.messagesLoaded || this.noMoreMessagesToLoad) {
      return
    }
    this.messagesLoaded = false
    this.getMessagesSubscription = this.messageService
      .getMessages(this.selectedRoom.id, this.messages[0].message.id)
      .subscribe((messages) => {
        if (messages.length !== 30) {
          this.noMoreMessagesToLoad = true
        }
        this.scheduleScrollPreserve()
        this.messages = [...this.messagesToUserMessages(messages), ...this.messages]
        this.messagesLoaded = true
        this.getMessagesSubscription = undefined
      })
  }

  private refreshMessages(messages: Message[]) {
    if (messages.length === 0) {
      return
    }
    if (
      this.messages.length !== 0 &&
      this.messages[this.messages.length - 1].message.id === messages[messages.length - 1].id
    ) {
      return
    }

    this.blinkTitleWithUnreadMessagesIfNotActive()

    this.messages = this.messagesToUserMessages(messages)
    if (this.autoScrollDown) {
      this.scheduleScrollToBottom()
    }
  }

  private addMessage(message: Message) {
    const scrollDown = this.autoScrollDown

    this.messages = [...this.messages, ...this.messagesToUserMessages([message])]

    this.blinkTitleWithUnreadMessagesIfNotActive()

    if (scrollDown) {
      this.scheduleScrollToBottom()
    }
  }

  private addAttachment(file: File) {
    if (this.attachments.length >= 10) {
      // TODO: Show some kind of error
      return
    }

    const reader = new FileReader()
    reader.readAsDataURL(file)

    const id = uuid()
    this.attachments = [...this.attachments, { id, loaded: false, file }]
    reader.onload = (e) => {
      if (!e.target || !e.target.result || e.target.result instanceof ArrayBuffer) {
        return
      }

      const dataUrl = e.target.result
      const attachment = this.attachments.find((attachment) => attachment.id === id)

      if (!attachment) {
        return
      }

      if (!dataUrl.startsWith('data:image')) {
        this.removeAttachment(id)
        return
      }

      attachment.loaded = true
      attachment.dataUrl = dataUrl
    }
  }

  private blinkTitleWithUnreadMessagesIfNotActive() {
    if (!this.isActiveTab.value) {
      this.titleService.title = `(1) ${this.title}`
      this.titleService.blink(this.title, { delay: 1000 })
    }
  }

  private messagesToUserMessages(messages: Message[]): UserMessage[] {
    // TODO: If user not found, refresh users
    return messages.map(
      (message) =>
        new UserMessage(
          message,
          this.users.find((user) => user.id === message.authorId) || USER_DUMMY_OTHER,
          this.me.id === message.authorId,
        ),
    )
  }

  public scheduleScrollToBottom() {
    this.autoScrollDownScheduled = true
  }

  private scheduleScrollPreserve() {
    this.preserveScrollHeight = this.chatScrollElement.nativeElement.scrollHeight
    this.preserveScrollTop = this.chatScrollElement.nativeElement.scrollTop
    this.preserveScrollScheduled = true
  }

  private scrollToBottom() {
    this.chatScrollElement.nativeElement.scrollTop =
      this.chatScrollElement.nativeElement.scrollHeight
  }

  private generateDummyUsers() {
    this.users = [USER_DUMMY_ME, USER_DUMMY_OTHER]
  }

  private generateDummyMessages() {
    this.messages = Array(20)
      .fill(0)
      .map(() => {
        const user = Math.random() > 0.5 ? this.me : USER_DUMMY_OTHER
        const text = Array(Math.ceil(Math.random() * 10 + 2))
          .fill(0)
          .map(() => '\u00A0'.repeat(Math.random() * 20 + 3))
          .join(' ')
        return {
          message: new Message(this.dummyUuid, user.id, text, new Date(), [], []),
          user,
          isMy: this.me.id === user.id,
        }
      })
  }

  private preserveScroll() {
    this.chatScrollElement.nativeElement.scrollTop =
      this.chatScrollElement.nativeElement.scrollHeight -
      this.preserveScrollHeight +
      this.preserveScrollTop
  }

  private onScrollHeightChange(oldHeight: number, newHeight: number) {
    if (newHeight > oldHeight && this.preserveScrollScheduled) {
      this.preserveScroll()
    }
    if (this.autoScrollDownScheduled) {
      this.autoScrollDownScheduled = false
      this.scrollToBottom()
    }
  }
}
