import { Pipe, PipeTransform } from '@angular/core'

@Pipe({ name: 'emoji' })
export class EmojiPipe implements PipeTransform {
  public transform(value: string): unknown {
    return twemoji.parse(value, {
      size: 'svg',
      base: 'https://cdnjs.cloudflare.com/ajax/libs/twemoji/13.1.0/',
      ext: '.svg',
    })
  }
}
