import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../../environments/environment'
import { User } from '../domain/user/user'
import { Observable, throwError } from 'rxjs'
import { AuthService } from './auth.service'

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly baseUrl = `${environment.apiUrl}/user`

  public constructor(private http: HttpClient, private authService: AuthService) {}

  public getMe(): Observable<User> {
    const uuid = this.authService.userId
    if (!uuid) {
      return throwError(() => new Error('Could not obtain user id.'))
    }

    return this.http.get<User>(`${this.baseUrl}/${uuid}`)
  }

  public getUsers(): Observable<User[]> {
    return this.http.get<User[]>(this.baseUrl)
  }
}
