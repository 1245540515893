import { Component, OnInit } from '@angular/core'
import { LightboxImage, LightboxService } from './lightbox.service'

@Component({
  selector: 'app-lightbox',
  templateUrl: './lightbox.component.html',
})
export class LightboxComponent implements OnInit {
  public opened = false
  private images: LightboxImage[] = []
  private selectedIndex = 0

  public get image(): LightboxImage {
    return this.images[this.selectedIndex]
  }

  public get counter(): string {
    return `${this.selectedIndex + 1} / ${this.images.length}`
  }

  public constructor(private lightboxService: LightboxService) {}

  public ngOnInit(): void {
    this.lightboxService.images$.subscribe(({ images, options }) => {
      this.images = images
      this.selectedIndex = options?.selectedIndex || 0
      this.opened = true
    })
  }

  public onPreviousClicked() {
    if (this.selectedIndex === 0) {
      this.selectedIndex = this.images.length - 1
      return
    }

    this.selectedIndex--
  }
  public onNextClicked() {
    if (this.selectedIndex + 1 >= this.images.length) {
      this.selectedIndex = 0
      return
    }

    this.selectedIndex++
  }
}
