import { Injectable } from '@angular/core'
import { environment } from '../../../environments/environment'
import { HttpClient } from '@angular/common/http'

export class FileResponseBody {
  public constructor(public id: string, public path: string, public createdAt: Date) {}
}

@Injectable({
  providedIn: 'root',
})
export class FileService {
  private readonly baseUrl = environment.apiUrl

  public constructor(private http: HttpClient) {}

  public uploadFile(roomId: string, file: File) {
    const data = new FormData()
    data.append('file', file, file.name)

    return this.http.post<FileResponseBody>(`${this.baseUrl}/room/${roomId}/file`, data)
  }
}
