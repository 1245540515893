import { User } from './user'
import { IMAGE_SHEEP } from '../../../IMAGE'

export const USER_DUMMY_ME = new User(
  '00000000-0000-0000-0000-000000000000',
  '',
  '',
  new Date(),
  IMAGE_SHEEP,
)
export const USER_DUMMY_OTHER = new User(
  '00000000-0000-0000-0000-000000000001',
  '',
  '',
  new Date(),
  IMAGE_SHEEP,
)
