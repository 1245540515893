export class React {
  public constructor(public id: string, public emoji: string, public name: string) {}
}

export const REACT_LOVE = new React('love', String.fromCodePoint(0x2764), 'Love')
export const REACT_LAUGH = new React('laugh', String.fromCodePoint(0x1f600), 'Laugh')
export const REACT_WOW = new React('wow', String.fromCodePoint(0x1f62e), 'Wow')
export const REACT_VOMIT = new React('vomit', String.fromCodePoint(0x1f92e), 'Vomit')
export const REACT_LIKE = new React('like', String.fromCodePoint(0x1f44d), 'Like')
export const REACT_UNKNOWN = new React('unknown', String.fromCodePoint(0x2753), 'Unknown react')

export const REACTS: React[] = [REACT_LOVE, REACT_LAUGH, REACT_WOW, REACT_VOMIT, REACT_LIKE]
