import { Observable } from 'rxjs'
import { InjectionToken, Provider } from '@angular/core'

import { HttpRoomService } from '../../infrastructure/http-room.service'
import { Room } from './room'

export const HTTP_ROOM_SERVICE = new InjectionToken<RoomService>('Http room service')

export const ROOM_SERVICE_PROVIDERS: Provider[] = [
  { provide: HTTP_ROOM_SERVICE, useClass: HttpRoomService },
]

export interface RoomService {
  getRooms(): Observable<Room[]>
}
