<ng-template #userProfile>
  <div *ngIf="user !== undefined" class="subitem subitem-user">
    <img class="profile-image" [src]="user.image || defaultUserImage" />
    {{ user.displayName }}
  </div>
</ng-template>

<nav class="nav">
  <header class="header">
    <img class="logo" src="assets/chat.svg" />
  </header>

  <div class="item-container">
    <div
      *ngFor="let item of menuMap"
      [ngClass]="{
        item: true,
        item__active: item.active
      }"
      (click)="onItemClick($event, item)"
    >
      <i *ngIf="item.icon" [class]="item.icon"></i>

      <span *ngIf="item.text">{{ item.text }}</span>

      <div class="subitem-container subitem-container__expand-left">
        <ng-container *ngFor="let subitem of item.subitems">
          <div class="subitem-wrapper" (click)="onSubitemClick($event, item, subitem)">
            <ng-container *ngIf="!subitem.template">
              <div class="subitem">
                <i *ngIf="subitem.icon" [class]="subitem.icon"></i>

                <span *ngIf="subitem.text">{{ subitem.text }}</span>
              </div>
            </ng-container>
            <ng-container *ngIf="subitem.template" [ngTemplateOutlet]="subitem.template">
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</nav>
