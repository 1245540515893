import { Component, EventEmitter, Inject, Input, Output } from '@angular/core'

import { LightboxImage, LightboxService } from '../../../core/lightbox/application/lightbox.service'
import { ReactPickerService } from '../react-picker/react-picker.service'
import { FileResolver } from '../../../file/infrastructure/file.resolver'

import { UserMessage } from '../../domain/message/user-message'
import { User } from '../../../user/domain/user/user'
import { Attachment } from '../../domain/message/message'
import { Rectangle } from '../../presentation/message/message-presentation.component'
import { map, switchMap, take } from 'rxjs/operators'
import { HTTP_MESSAGE_SERVICE, MessageService } from '../../domain/message/message.service'
import { filter, merge } from 'rxjs'
import { React, REACTS } from '../../domain/message/react'
import {
  ContextMenuAction,
  MessageContextMenuService,
  ReactAction,
  UnreactAction,
} from './message-context-menu/message-context-menu.service'

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
})
export class MessageComponent {
  @Input() public previousMessage?: UserMessage
  @Input() public message!: UserMessage
  @Input() public nextMessage?: UserMessage
  @Input() public me!: User

  @Output() public attachmentLoaded = new EventEmitter<undefined>()

  public constructor(
    @Inject(HTTP_MESSAGE_SERVICE)
    private messageService: MessageService,
    private fileResolver: FileResolver,
    private reactPickerService: ReactPickerService,
    private lightboxService: LightboxService,
    private messageContextMenuService: MessageContextMenuService,
  ) {}

  public onAttachmentLoaded() {
    this.attachmentLoaded.emit()
  }

  public shouldShowTime(): boolean {
    if (!this.previousMessage) {
      return true
    }

    return (
      (this.message.message.createdAt.getTime() -
        this.previousMessage.message.createdAt.getTime()) /
        1000 /
        60 >
      60
    )
  }

  public shouldConnectToPreviousMessage(): boolean {
    if (!this.previousMessage) {
      return false
    }

    return this.shouldConnect(this.previousMessage, this.message)
  }

  public shouldConnectToNextMessage(): boolean {
    if (!this.nextMessage) {
      return false
    }

    return this.shouldConnect(this.message, this.nextMessage)
  }

  public shouldDisplayUsernameHeader() {
    return !this.message.isMy && !this.shouldConnectToPreviousMessage()
  }

  private shouldConnect(a: UserMessage, b: UserMessage) {
    return (
      a.user.id === b.user.id &&
      (b.message.createdAt.getTime() - a.message.createdAt.getTime()) / 1000 / 60 < 60
    )
  }

  public onAttachmentClicked(attachment: Attachment) {
    this.lightboxService.show(
      this.message.message.attachments.map(
        (attachment) =>
          ({
            path: this.fileResolver.resolve(attachment.path),
          } as LightboxImage),
      ),
      {
        selectedIndex: this.message.message.attachments.findIndex((a) => a.id === attachment.id),
      },
    )
  }

  public onReactActionClick(target: Rectangle) {
    const myReactId = this.message.message.reacts.find(
      (react) => this.me.id === react.userId,
    )?.reactId

    this.reactPickerService.show(myReactId, target.left, target.top, target.width, target.height)

    const HIDE = 'hide'
    merge(this.reactPickerService.$reacts, this.reactPickerService.$hide.pipe(map(() => HIDE)))
      .pipe(
        take(1),
        filter((reactOrHide): reactOrHide is React | undefined => reactOrHide !== HIDE),
        switchMap((react) =>
          react
            ? this.messageService.react(this.message.message.id, react.id)
            : this.messageService.unreact(this.message.message.id),
        ),
      )
      .subscribe()
  }

  public onContentTapHold() {
    const HIDE = 'hide'

    this.messageContextMenuService.show(
      this.message,
      REACTS.find(
        (react) =>
          react.id ===
          this.message.message.reacts.find(({ userId }) => userId === this.me.id)?.reactId,
      ),
    )

    merge(
      this.messageContextMenuService.actions$,
      this.messageContextMenuService.hide$.pipe(map(() => HIDE)),
    )
      .pipe(
        take(1),
        filter((actionOrHide): actionOrHide is ContextMenuAction => actionOrHide !== HIDE),
        filter((action): action is ReactAction | UnreactAction =>
          ['react', 'unreact'].includes(action.action),
        ),
        switchMap((action) =>
          action.action === 'react'
            ? this.messageService.react(this.message.message.id, action.react.id)
            : this.messageService.unreact(this.message.message.id),
        ),
      )
      .subscribe()
  }
}
