import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

interface LightboxOptions {
  selectedIndex?: number
}

export interface LightboxImage {
  path: string
  description?: string
}

interface LightboxData {
  images: LightboxImage[]
  options?: LightboxOptions
}

@Injectable({
  providedIn: 'root',
})
export class LightboxService {
  public images$ = new Subject<LightboxData>()

  public show(images: LightboxImage[], options: LightboxOptions = {}) {
    this.images$.next({ images, options })
  }
}
