import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core'
import { Room } from '../../domain/room/room'

@Component({
  selector: 'app-room-list',
  templateUrl: './room-list.component.html',
})
export class RoomListComponent implements OnChanges {
  @Input() public rooms: Room[] = []

  @Output() public roomSelected = new EventEmitter<Room>()

  @Input() public selectedRoom?: Room

  public ngOnChanges(changes: SimpleChanges) {
    const rooms = changes.rooms.currentValue as Room[]

    const sameRoomAsSelected = rooms.find((room) => room.id === this.selectedRoom?.id)
    if (sameRoomAsSelected) {
      this.selectedRoom = sameRoomAsSelected
    } else {
      this.selectedRoom = rooms.length > 0 ? rooms[0] : undefined
      this.roomSelected.emit(this.selectedRoom)
    }
  }

  public onSelect(room: Room) {
    this.selectedRoom = room
    this.roomSelected.emit(room)
  }
}
