import { Injectable } from '@angular/core'
import { environment } from '../../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class FileResolver {
  public resolve(path: string) {
    return path.startsWith('http:') || path.startsWith('https:') || path.startsWith('data:')
      ? path
      : `${environment.staticUrl}/${path}`
  }
}
