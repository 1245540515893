import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { BrowserModule } from '@angular/platform-browser'
import { FormsModule } from '@angular/forms'
import { RouterModule, Routes } from '@angular/router'
import { AuthGuard } from '../auth/app/auth.guard'

import { CoreModule } from '../core/core.module'
import { EmojiPipe } from '../app/emoji.pipe'
import { ChatComponent } from './application/chat/chat.component'
import { MessageComponent } from './application/message/message.component'
import { MessagePresentationComponent } from './presentation/message/message-presentation.component'
import { RoomListComponent } from './application/room/room-list.component'
import { RoomComponent } from './application/room/room.component'
import { RoomPresentationComponent } from './presentation/room/room-presentation.component'
import { ReactPickerComponent } from './application/react-picker/react-picker.component'
import { MESSAGE_SERVICE_PROVIDERS } from './domain/message/message.service'
import { ROOM_SERVICE_PROVIDERS } from './domain/room/room.service'
import { MessageContextMenuComponent } from './application/message/message-context-menu/message-context-menu.component'

const routes: Routes = [{ path: '', component: ChatComponent, canActivate: [AuthGuard] }]

@NgModule({
  declarations: [
    ChatComponent,
    MessageComponent,
    MessagePresentationComponent,
    EmojiPipe,
    RoomListComponent,
    RoomComponent,
    RoomPresentationComponent,
    ReactPickerComponent,
    MessageContextMenuComponent,
  ],
  imports: [BrowserModule, FormsModule, CommonModule, RouterModule.forChild(routes), CoreModule],
  providers: [...MESSAGE_SERVICE_PROVIDERS, ...ROOM_SERVICE_PROVIDERS],
})
export class ChatModule {}
