import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { Room } from '../domain/room/room'
import { environment } from '../../../environments/environment'
import { HttpClient } from '@angular/common/http'
import { RoomService } from '../domain/room/room.service'

@Injectable({
  providedIn: 'root',
})
export class HttpRoomService implements RoomService {
  private readonly baseUrl = `${environment.apiUrl}/room`

  public constructor(private http: HttpClient) {}

  public getRooms(): Observable<Room[]> {
    return this.http.get<Room[]>(this.baseUrl)
  }
}
