import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'

import { AppComponent } from './app.component'
import { FormsModule } from '@angular/forms'
import { HttpClientModule } from '@angular/common/http'
import { httpInterceptorProviders } from './app/http-interceptors'
import { RouterModule, Routes } from '@angular/router'
import { LoginComponent } from './auth/app/login/login.component'
import { HeaderComponent } from './layout/app/header/header.component'
import { RegisterComponent } from './auth/app/register/register.component'
import { InvitationFormComponent } from './auth/app/register/invitation-form/invitation-form.component'
import { RegistrationFormComponent } from './auth/app/register/register-form/registration-form.component'
import { CoreModule } from './core/core.module'
import { ChatModule } from './chat/chat.module'

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
]

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    HeaderComponent,
    InvitationFormComponent,
    RegistrationFormComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes),
    HttpClientModule,
    FormsModule,
    CoreModule,
    ChatModule,
  ],
  providers: [httpInterceptorProviders],
  bootstrap: [AppComponent],
})
export class AppModule {}
