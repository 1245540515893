import { Attachment, Message } from '../domain/message/message'
import { UserReact } from '../domain/message/user-react'

export const isCachedMessage = (message: Message): message is CachedMessage => 'cached' in message

export class CachedMessage extends Message {
  public readonly cached = true

  public constructor(
    id: string,
    authorId: string,
    text: string,
    createdAt: Date,
    attachments: Attachment[],
    reacts: UserReact[],
  ) {
    super(id, authorId, text, createdAt, attachments, reacts)
  }
}
