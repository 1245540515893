/* "Barrel" of Http Interceptors */
import { HTTP_INTERCEPTORS } from '@angular/common/http'
import { AuthInterceptor } from '../user/infrastructure/auth-interceptor'
import { DebugInterceptor } from '../core/debug/debug-interceptor'
import { environment } from '../../environments/environment'

const devInterceptors = [{ provide: HTTP_INTERCEPTORS, useClass: DebugInterceptor, multi: true }]

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
  ...(environment.production ? [] : devInterceptors),
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
]
