<section class="register-container">
  <div class="register">
    <header class="header">
      <img class="logo" src="assets/chat.svg" />
    </header>

    <div *ngIf="error" class="error">
      {{ error.message }}
    </div>

    <app-registration-form
      *ngIf="invitationId"
      [email]="invitation.email"
      [enabled]="invitation && invitation.isApproved && !invitation.isUsed"
      (submitted)="register($event)"
    ></app-registration-form>
    <app-invitation-form *ngIf="!invitationId" (submitted)="invite($event)"></app-invitation-form>
  </div>
</section>
