import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

import * as Sentry from '@sentry/angular'
import { Integrations } from '@sentry/tracing'

import { AppModule } from './app/app.module'
import { environment } from './environments/environment'

if (environment.production) {
  enableProdMode()
}

Sentry.init({
  dsn: 'https://840f8b8fff154de9a56d0a0ef4e232d6@o288901.ingest.sentry.io/5767300',
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: ['localhost', 'https://chat-api.jirigracik.cz'],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  environment: environment.production ? 'production' : 'development',
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: environment.production ? 0.2 : 0,
})

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err))
