<form class="invitation" (submit)="onSubmit($event)">
  <p class="info">Registrations are currently invitation only.</p>
  <div class="input-group">
    <label class="hidden">Email</label>
    <input name="email" [(ngModel)]="email" placeholder="Email" />
  </div>

  <div class="input-group">
    <input class="submit" type="submit" value="Invite me" />
  </div>

  <div class="links">
    <a [routerLink]="['/login']">Login</a>
  </div>
</form>
