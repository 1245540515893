import { AfterViewInit, Component, HostListener, TemplateRef, ViewChild } from '@angular/core'
import { AuthService } from '../../../user/infrastructure/auth.service'
import { NavigationEnd, Router } from '@angular/router'
import { User } from '../../../user/domain/user/user'
import { UserService } from '../../../user/infrastructure/user.service'
import { filter, Subscription } from 'rxjs'
import { IMAGE_SHEEP } from '../../../IMAGE'

interface Item {
  readonly text?: string
  readonly icon?: string
  readonly subitems?: Subitem[]
  readonly action?: (itemAction: { event: MouseEvent; item: Item }) => void | Promise<void>
  active: boolean
}

type Subitem = {
  readonly template?: TemplateRef<object>
  readonly text?: string
  readonly icon?: string
  readonly action?: (subitemAction: {
    event: MouseEvent
    item: Item
    subitem: Subitem
  }) => void | Promise<void>
}

type HandableMouseEvent = MouseEvent & { handled?: boolean }

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements AfterViewInit {
  public readonly defaultUserImage = IMAGE_SHEEP

  public user?: User
  private userSubscription?: Subscription

  @ViewChild('userProfile', { read: TemplateRef }) public userProfile!: TemplateRef<object>

  public menuMap: Item[] = []

  public constructor(
    public authService: AuthService,
    public userService: UserService,
    private router: Router,
  ) {}

  public ngAfterViewInit(): void {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.fetchUserIfPossible()
      this.initMenu()
    })

    this.fetchUserIfPossible()
    this.initMenu()
  }

  private initMenu() {
    this.menuMap = this.authService.isAuthenticated
      ? [
          {
            icon: 'bi-list',
            active: false,
            subitems: [
              { template: this.userProfile },
              {
                text: 'Logout',
                icon: 'bi-door-open',
                action: () => this.onLogoutClick(),
              },
            ],
          },
        ]
      : [
          {
            text: 'Login',
            icon: 'bi-person-fill',
            active: false,
            action: () => this.onLoginClick(),
          },
        ]
  }

  private fetchUserIfPossible() {
    if (
      this.user === undefined &&
      this.userSubscription === undefined &&
      this.authService.isAuthenticated
    ) {
      this.userSubscription = this.userService.getMe().subscribe((user) => {
        this.user = user
        this.userSubscription = undefined
      })
    }
  }

  @HostListener('document:click', ['$event'])
  public onClickOutside(event: HandableMouseEvent) {
    if (!event.handled) {
      this.closeItems()
    }
  }

  public async onItemClick(event: HandableMouseEvent, clickedItem: Item) {
    const wasActive = clickedItem.active
    this.closeItems()

    clickedItem.active = !wasActive
    if (clickedItem.action) {
      const result = clickedItem.action({ event, item: clickedItem })

      if (result instanceof Promise) {
        await result
      }
    }

    event.handled = true

    return event
  }

  public async onSubitemClick(event: HandableMouseEvent, item: Item, subitem: Subitem) {
    if (subitem.action) {
      const result = subitem.action({ event, item, subitem })

      if (result instanceof Promise) {
        await result
      }
    }

    event.handled = true

    return event
  }

  public async onLoginClick() {
    await this.router.navigate(['/login'])
  }

  public async onLogoutClick() {
    this.authService.logout()
    await this.router.navigate(['/login'])
  }

  private closeItems() {
    for (const item of this.menuMap) {
      item.active = false
    }
  }
}
