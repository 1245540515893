import { Component, OnInit } from '@angular/core'
import { AuthService } from '../../../user/infrastructure/auth.service'
import { TitleService } from '../../../app/infrastructure/title.service'
import { ActivatedRoute, Router } from '@angular/router'
import { HttpErrorResponse } from '@angular/common/http'
import { Invitation } from '../../domain/invitation'
import { InvitationService } from '../../infrastructure/invitation.service'
import { RegistrationFormPayload } from './register-form/registration-form.component'
import { InvitationFormPayload } from './invitation-form/invitation-form.component'
import { Location } from '@angular/common'

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  public error?: ErrorResponse<{ invitationId?: string }>
  public invitationId?: string
  public invitation: Invitation = new Invitation('', '', true, false)

  public constructor(
    private authService: AuthService,
    private invitationService: InvitationService,
    private titleService: TitleService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
  ) {}

  public ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.invitationId = (params.invitationId as string | undefined) || undefined
      this.error = undefined

      if (this.invitationId) {
        this.invitationService.getInvitation(this.invitationId).subscribe({
          next: (invitation) => (this.invitation = invitation),
          error: (error: HttpErrorResponse) => (this.error = error.error as ErrorResponse<object>),
        })
      }
    })

    this.titleService.title = 'Register'
  }

  public register(payload: RegistrationFormPayload) {
    this.error = undefined

    this.authService.register(payload.email, payload.displayName, payload.password).subscribe({
      next: () => this.router.navigate(['/']),
      error: (e: HttpErrorResponse) => (this.error = e.error as ErrorResponse<object>),
    })
  }

  public invite(payload: InvitationFormPayload) {
    this.error = undefined

    this.invitationService.createInvitation(payload.email).subscribe({
      next: (invitation) => {
        this.error = { code: '', message: 'Invitation created, but not approved yet.' }
        this.invitation = invitation
        this.redirectToInvitation(invitation.id)
      },
      error: (e: HttpErrorResponse) => {
        this.error = e.error as ErrorResponse<object>
        if (this.error && this.error.data && this.error.data.invitationId) {
          this.invitation.email = payload.email
          this.redirectToInvitation(this.error.data.invitationId)
        }
      },
    })
  }

  private redirectToInvitation(invitationId: string) {
    this.invitationId = invitationId
    this.location.go(
      this.router
        .createUrlTree([], {
          relativeTo: this.route,
          queryParams: { invitationId },
          queryParamsHandling: 'merge',
        })
        .toString(),
    )
  }
}

interface ErrorResponse<T> {
  code: string
  message: string
  data?: T
}
