import { Injectable } from '@angular/core'
import { environment } from '../../../environments/environment'
import { HttpClient } from '@angular/common/http'
import { BehaviorSubject, filter, Observable, of, skip, throwError } from 'rxjs'
import { map, switchMap, take, tap } from 'rxjs/operators'
import jwtDecode, { JwtPayload } from 'jwt-decode'

interface Tokens {
  authToken: string
  refreshToken: string
}

interface OptionalTokens {
  authToken?: string
  refreshToken?: string
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly baseUrl = `${environment.apiUrl}`

  public readonly tokens$: BehaviorSubject<OptionalTokens>
  public readonly authToken$: BehaviorSubject<string | undefined>
  public readonly refreshToken$: BehaviorSubject<string | undefined>

  private isRefreshing = false

  public get logoutReason(): string | undefined {
    const reason = localStorage.getItem('logoutReason')

    return reason !== null ? reason : undefined
  }

  private set logoutReason(logoutReason: string | undefined) {
    if (logoutReason) {
      localStorage.setItem('logoutReason', logoutReason)
    } else {
      localStorage.removeItem('logoutReason')
    }
  }

  public get userId(): string | undefined {
    const authToken = this.authToken$.value
    if (authToken) {
      return jwtDecode<JwtPayload & { uid?: string }>(authToken).uid
    }
    return undefined
  }

  private set authToken(token: string | undefined) {
    if (token) {
      localStorage.setItem('authToken', token)
    } else {
      localStorage.removeItem('authToken')
    }
  }

  private set refreshToken(token: string | undefined) {
    if (token) {
      localStorage.setItem('refreshToken', token)
    } else {
      localStorage.removeItem('refreshToken')
    }
  }

  public get isAuthenticated(): boolean {
    return !!this.authToken$.value
  }

  public constructor(private http: HttpClient) {
    this.tokens$ = new BehaviorSubject<OptionalTokens>({
      authToken: this.getTokenFromLocalStorage('authToken'),
      refreshToken: this.getTokenFromLocalStorage('refreshToken'),
    })

    this.authToken$ = new BehaviorSubject<string | undefined>(this.tokens$.value.authToken)
    this.refreshToken$ = new BehaviorSubject<string | undefined>(this.tokens$.value.refreshToken)

    this.tokens$.subscribe(({ authToken, refreshToken }) => {
      this.authToken$.next(authToken)
      this.refreshToken$.next(refreshToken)

      this.authToken = authToken
      this.refreshToken = refreshToken
    })
  }

  public login(email: string, password: string): Observable<Tokens> {
    return this.tapForTokens(
      this.http.post<Tokens>(`${this.baseUrl}/login`, { username: email, password }),
    )
  }

  public register(email: string, displayName: string, password: string): Observable<Tokens> {
    return this.tapForTokens(
      this.http.post<Tokens>(`${this.baseUrl}/registration`, {
        username: email,
        displayName,
        password,
      }),
    )
  }

  public refresh(): Observable<Tokens> {
    return this.tapForTokens(
      this.http.post<Tokens>(`${this.baseUrl}/refresh`, {
        authToken: this.authToken$.value,
        refreshToken: this.refreshToken$.value,
      }),
    )
  }

  public getValidAuthToken(waitForUserLogin: boolean = false): Observable<string> {
    const authToken = this.authToken$.value

    if (authToken && this.isTokenValid(authToken)) {
      return of(authToken)
    }

    if (!this.isRefreshing) {
      const refreshToken = this.refreshToken$.value
      if (refreshToken && this.isTokenValid(refreshToken)) {
        return this.refresh().pipe(map(({ authToken }) => authToken))
      }
      if (!waitForUserLogin) {
        this.logout('Please login again.')
        return throwError(() => new Error('Could not obtain auth token.'))
      }
    }

    return this.authToken$.pipe(
      skip(1),
      filter((token) => !!token),
      take(1),
      switchMap((token) =>
        !!token && this.isTokenValid(token)
          ? of(token)
          : throwError(() => new Error('Got invalid error after refresh')),
      ),
    )
  }

  public logout(logoutReason?: string) {
    this.logoutReason = logoutReason
    this.tokens$.next({})
  }

  public isTokenValid(token: string): boolean {
    try {
      const payload = jwtDecode<JwtPayload>(token)

      return !!(payload && payload.exp && +payload.exp - new Date().getTime() / 1000 > 1)
    } catch (e) {
      return false
    }
  }

  public clearLogoutReason() {
    this.logoutReason = undefined
  }

  private tapForTokens(observable: Observable<Tokens>): Observable<Tokens> {
    this.isRefreshing = true
    return observable.pipe(
      tap({
        next: (tokens) => {
          this.isRefreshing = false
          this.tokens$.next(tokens)
        },
        error: () => {
          this.isRefreshing = false
          this.tokens$.next({})
        },
      }),
    )
  }

  private getTokenFromLocalStorage(storageKey: string): string | undefined {
    return localStorage.getItem(storageKey) || undefined
  }
}
