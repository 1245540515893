import { Injectable } from '@angular/core'
import { environment } from '../../../environments/environment'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { Invitation } from '../domain/invitation'

@Injectable({
  providedIn: 'root',
})
export class InvitationService {
  private readonly baseUrl = `${environment.apiUrl}/invitation`

  public constructor(private http: HttpClient) {}

  public createInvitation(email: string) {
    return this.http.post<Invitation>(this.baseUrl, { email })
  }

  public getInvitation(invitationId: string): Observable<Invitation> {
    return this.http.get<Invitation>(`${this.baseUrl}/${invitationId}`)
  }
}
