import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import { LightboxImage } from '../application/lightbox.service'

type GenericSimpleChange<T> = { previousValue?: T; currentValue?: T }

@Component({
  selector: 'app-lightbox-presentation',
  templateUrl: './lightbox-presentation.component.html',
  styleUrls: ['./lightbox-presentation.component.scss'],
})
export class LightboxPresentationComponent implements OnChanges {
  @Input() public image!: LightboxImage
  @Input() public counter?: string
  @Output() public previousClicked = new EventEmitter<undefined>()
  @Output() public nextClicked = new EventEmitter<undefined>()
  @Output() public closed = new EventEmitter<undefined>()
  @ViewChild('img') public imageElement?: ElementRef<HTMLImageElement>

  public aspectRatio = 'auto'

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.image) {
      const { previousValue, currentValue } = changes.image as GenericSimpleChange<LightboxImage>

      if (previousValue && currentValue && previousValue.path !== currentValue.path) {
        this.aspectRatio = 'auto'
      }
    }
  }

  public onPreviousClick(e: Event) {
    e.preventDefault()
    e.stopPropagation()
    this.previousClicked.emit()
  }

  public onNextClick(e: Event) {
    e.preventDefault()
    e.stopPropagation()
    this.nextClicked.emit()
  }

  @HostListener('window:keydown', ['$event'])
  public handleKeyboardEvent(event: KeyboardEvent) {
    if (!['Escape', 'ArrowLeft', 'ArrowRight'].includes(event.code)) {
      return
    }

    event.preventDefault()
    event.stopPropagation()

    switch (event.code) {
      case 'Escape':
        this.closed.emit()
        break
      case 'ArrowLeft':
        this.previousClicked.emit()
        break
      case 'ArrowRight':
        this.nextClicked.emit()
        break
    }
  }

  public onCloseActionClick(event: Event) {
    event.preventDefault()
    event.stopPropagation()

    this.closed.emit()
  }

  public onBackgroundClick() {
    this.closed.emit()
  }

  public onImageLoad(event: Event) {
    if (event && event.target) {
      const element = event.target as HTMLImageElement
      const width = element.naturalWidth
      const height = element.naturalHeight

      this.aspectRatio = `${width} / ${height}`
    }
  }
}
