import { Component, EventEmitter, Input, Output } from '@angular/core'
import { Room } from '../../domain/room/room'

@Component({
  selector: 'app-room',
  templateUrl: './room.component.html',
})
export class RoomComponent {
  @Input() public room!: Room
  @Input() public isSelected = false

  @Output() public selected = new EventEmitter<Room>()

  public onSelect(room: Room) {
    this.selected.emit(room)
  }
}
