import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'
import { UserMessage } from '../../../domain/message/user-message'
import { React } from '../../../domain/message/react'

export interface ReactAction {
  action: 'react'
  react: React
}

export interface UnreactAction {
  action: 'unreact'
}

export type ContextMenuAction = { action: 'copy' | 'reply' } | ReactAction | UnreactAction

@Injectable({
  providedIn: 'root',
})
export class MessageContextMenuService {
  public show$ = new Subject<{ message: UserMessage; selectedReact: React | undefined }>()
  public hide$ = new Subject<undefined>()
  public actions$ = new Subject<ContextMenuAction>()

  public show(message: UserMessage, selectedReact?: React) {
    this.show$.next({ message, selectedReact })
  }

  public hide() {
    this.hide$.next(undefined)
  }

  public action(action: ContextMenuAction) {
    this.actions$.next(action)
  }
}
