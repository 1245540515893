<div
  *ngIf="show"
  [ngClass]="{
    'context-menu-container': true,
    'context-menu-container__visible': visible,
    'context-menu-container__invisible': !visible
  }"
  (click)="onBackgroundClick()"
  (contextmenu)="onContextMenu($event)"
>
  <div class="context-menu">
    <app-message-presentation *ngIf="message" [message]="message"></app-message-presentation>

    <ul class="options">
      <li class="reacts">
        <a
          *ngFor="let react of reacts"
          [ngClass]="{
            react: true,
            react__selected: react.id === selectedReact?.id
          }"
          (click)="onReactClick(react)"
        >
          <div class="emoji-wrapper" [innerHTML]="react.emoji | emoji"></div>
        </a>
      </li>
      <li (click)="onCopyClick()"><i class="bi-files"></i> Copy</li>
      <li><i class="bi-reply"></i> Reply</li>
    </ul>
  </div>
</div>
