import { UserReact } from './user-react'
import { React } from './react'

export class Attachment {
  public constructor(public id: string, public path: string, public createdAt: Date) {}
}

export class Message {
  public constructor(
    public id: string,
    public authorId: string,
    public text: string,
    public createdAt: Date,
    public attachments: Attachment[],
    public reacts: UserReact[],
  ) {}

  public react(userId: string, react: React) {
    this.unreact(userId)
    this.reacts = [...this.reacts, new UserReact(userId, react.id)]
  }

  public unreact(userId: string) {
    this.reacts = this.reacts.filter((react) => react.userId !== userId)
  }
}

export interface PrimitiveAttachment {
  id: string
  path: string
  createdAt: string
}

export interface PrimitiveUserReact {
  userId: string
  reactId: string
}

export interface PrimitiveMessage {
  id: string
  authorId: string
  text: string
  createdAt: string
  attachments: PrimitiveAttachment[]
  reacts: PrimitiveUserReact[]
}

export const primitiveMessageToMessage = (message: PrimitiveMessage) =>
  new Message(
    message.id,
    message.authorId,
    message.text,
    new Date(message.createdAt),
    message.attachments.map(
      ({ id, path, createdAt }) => new Attachment(id, path, new Date(createdAt)),
    ),
    message.reacts.map(({ userId, reactId }) => new UserReact(userId, reactId)),
  )
