import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
  selector: 'app-registration-form',
  templateUrl: './registration-form.component.html',
  styleUrls: ['./registration-form.component.scss'],
})
export class RegistrationFormComponent {
  @Input() public enabled = false
  @Input() public email = ''
  public displayName = ''
  public password = ''

  @Output() public submitted = new EventEmitter<RegistrationFormPayload>()

  public onSubmit(e: Event) {
    e.preventDefault()

    if (this.enabled) {
      this.submitted.emit(new RegistrationFormPayload(this.email, this.displayName, this.password))
    }
  }
}

export class RegistrationFormPayload {
  public constructor(public email: string, public displayName: string, public password: string) {}
}
