import { Observable } from 'rxjs'
import { InjectionToken, Provider } from '@angular/core'

import { HttpMessageService } from '../../infrastructure/http-message.service'
import { CachedMessageService } from '../../infrastructure/cached-message.service'
import { Message } from './message'

export const HTTP_MESSAGE_SERVICE = new InjectionToken<MessageService>('Http message service')
export const CACHED_MESSAGE_SERVICE = new InjectionToken<MessageService>('Cached message service')

export const MESSAGE_SERVICE_PROVIDERS: Provider[] = [
  { provide: HTTP_MESSAGE_SERVICE, useClass: HttpMessageService },
  { provide: CACHED_MESSAGE_SERVICE, useClass: CachedMessageService },
]

export interface CreateMessageRequestBodyAttachment {
  id: string
}

export interface MessageService {
  getMessages(roomId: string, before?: string): Observable<Message[]>

  sendMessage(
    roomId: string,
    text: string,
    attachments: CreateMessageRequestBodyAttachment[],
  ): Observable<object>

  react(messageId: string, reactId: string): Observable<object>

  unreact(messageId: string): Observable<object>
}
