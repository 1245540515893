import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SocketComponent } from './socket/socket.component'
import { LoaderComponent } from './loader/loader/loader.component'
import { LightboxComponent } from './lightbox/application/lightbox.component'
import { LightboxPresentationComponent } from './lightbox/presentation/lightbox-presentation.component'
import { TapHoldDirective } from './event/tap-hold.directive'

@NgModule({
  declarations: [
    SocketComponent,
    LoaderComponent,
    LightboxComponent,
    LightboxPresentationComponent,
    TapHoldDirective,
  ],
  exports: [SocketComponent, LoaderComponent, LightboxComponent, TapHoldDirective],
  imports: [CommonModule],
})
export class CoreModule {}
