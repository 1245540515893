import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http'
import { Observable, throwError } from 'rxjs'
import { Injectable } from '@angular/core'
import { catchError, switchMap } from 'rxjs/operators'
import { AuthService } from './auth.service'

@Injectable()
export class AuthInterceptor<T> implements HttpInterceptor {
  public constructor(private authService: AuthService) {}

  public intercept(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    const authToken = this.authService.authToken$.value

    if (authToken) {
      if (!this.authService.isTokenValid(authToken) && !request.url.includes('/refresh')) {
        return this.retryAfterRefresh(request, next)
      }
      request = this.withAuthHeader(request, authToken)
    }

    return next.handle(request).pipe(
      catchError((error) => {
        if (
          !(error instanceof HttpErrorResponse) ||
          error.status !== 401 ||
          request.url.includes('/login')
        ) {
          return throwError(error)
        }
        return this.retryAfterRefresh(request, next)
      }),
    )
  }

  private retryAfterRefresh(request: HttpRequest<T>, next: HttpHandler) {
    return this.authService.getValidAuthToken().pipe(
      switchMap((token) => {
        return next.handle(this.withAuthHeader(request, token))
      }),
    )
  }

  private withAuthHeader(request: HttpRequest<T>, authToken: string) {
    return request.clone({
      headers: request.headers.set('Authorization', 'Bearer ' + authToken),
    })
  }
}
