import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core'
import { React, REACTS } from '../../domain/message/react'
import { ReactPickerService, SummonData } from './react-picker.service'
import { fromEvent, Subscription } from 'rxjs'

@Component({
  selector: 'app-react-picker',
  templateUrl: './react-picker.component.html',
  styleUrls: ['./react-picker.component.scss'],
})
export class ReactPickerComponent implements OnInit {
  public readonly reacts = REACTS

  @ViewChild('reactPicker') public reactPicker!: ElementRef<HTMLDivElement>
  public visible = false
  public selectedReactId?: string
  public top = '0'
  public left = '0'
  private currentSummon?: SummonData
  private scrollSubscription?: Subscription

  public constructor(private reactPickerService: ReactPickerService) {}

  public ngOnInit(): void {
    this.reactPickerService.$show.subscribe((summon) => {
      if (
        this.visible &&
        this.currentSummon &&
        JSON.stringify(this.currentSummon) === JSON.stringify(summon)
      ) {
        return
      }

      this.currentSummon = summon
      const box = this.reactPicker.nativeElement.getBoundingClientRect()

      const topAbove = summon.top - box.height
      const topUnder = summon.top + summon.height
      const top = Math.round(topAbove >= 0 ? topAbove : topUnder)
      const calculatedLeft = summon.left + summon.width / 2 - box.width / 2
      const left = Math.min(
        Math.max(calculatedLeft, 16),
        document.documentElement.clientWidth - box.width - 16,
      )

      this.scrollSubscription = fromEvent(document, 'scroll', { capture: true }).subscribe(() =>
        this.reactPickerService.hide(),
      )

      this.selectedReactId = summon.selectedReactId
      this.top = `${top}px`
      this.left = `${left}px`

      this.visible = true
    })

    this.reactPickerService.$hide.subscribe(() => this.hide())
  }

  public onReactClick(react: React) {
    this.reactPickerService.react(this.selectedReactId !== react.id ? react : undefined)
  }

  @HostListener('document:click')
  public onClickOutside() {
    this.reactPickerService.hide()
  }

  private hide() {
    this.currentSummon = undefined
    this.visible = false
    this.selectedReactId = undefined

    if (this.scrollSubscription) {
      this.scrollSubscription.unsubscribe()
      this.scrollSubscription = undefined
    }
  }
}
