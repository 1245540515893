import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core'

@Directive({
  selector: '[appTapHold]',
})
export class TapHoldDirective {
  @Input() public tapHoldTimeout = 500
  @Output('appTapHold') public tapHold = new EventEmitter<TouchEvent>()

  private timeoutId?: number

  @HostListener('touchstart', ['$event'])
  public onTouchStart(event: TouchEvent) {
    this.timeoutId = setTimeout(() => {
      event.preventDefault()
      event.stopPropagation()

      this.timeoutId = undefined
      event.stopPropagation()
      this.tapHold.emit(event)
    }, this.tapHoldTimeout)
  }

  @HostListener('contextmenu', ['$event'])
  public onContextMenu(event: MouseEvent) {
    event.preventDefault()
  }

  @HostListener('touchend', ['$event'])
  @HostListener('touchmove', ['$event'])
  public onTouchEnd() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
    }
  }
}
