import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
  selector: 'app-invitation-form',
  templateUrl: './invitation-form.component.html',
  styleUrls: ['./invitation-form.component.scss'],
})
export class InvitationFormComponent {
  @Input() public email = ''

  @Output() public submitted = new EventEmitter<InvitationFormPayload>()

  public onSubmit(e: Event) {
    e.preventDefault()

    this.submitted.emit(new InvitationFormPayload(this.email))
  }
}

export class InvitationFormPayload {
  public constructor(public email: string) {}
}
