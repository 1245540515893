<div class="chat-wrapper">
  <section class="rooms">
    <app-room-list [rooms]="rooms" (roomSelected)="onSelectedRoomChange($event)"></app-room-list>
  </section>

  <section class="chat" [ngClass]="{ chat__loaded: loaded }" #chatScroll (scroll)="onChatScroll()">
    <div class="message-container">
      <app-message
        *ngFor="let userMessage of messages; let i = index"
        [previousMessage]="i > 0 ? messages[i - 1] : undefined"
        [message]="userMessage"
        [nextMessage]="i + 1 < messages.length ? messages[i + 1] : undefined"
        [me]="me"
        (attachmentLoaded)="onAttachmentLoaded()"
      ></app-message>
    </div>

    <footer class="chat-sticky-bottom">
      <header class="flash-message-container">
        <div
          [ngClass]="{ 'flash-message': true, 'flash-message__visible': !latestMessagesLoaded() }"
        >
          <app-loader [color]="'#999'"></app-loader>
          Loading new messages
        </div>
      </header>
      <a
        [ngClass]="{ 'scroll-to-bottom': true, 'hidden': autoScrollDown }"
        (click)="onScrollDownButtonClick()"
        ><span>Scroll down</span></a
      >
      <div *ngIf="attachments.length > 0" class="create-attachments">
        <div
          *ngFor="let attachment of attachments"
          class="create-attachment"
          [attr.data-type]="'media'"
          [attr.data-subtype]="'image'"
          (click)="removeAttachment(attachment.id)"
        >
          <img *ngIf="attachment.loaded" [src]="attachment.dataUrl" />
          <a class="remove">
            <i class="bi-trash3"></i>
          </a>
        </div>
      </div>
      <div class="create-message-errors">
        <div *ngIf="message.length > MAX_LENGTH" class="message-error">
          {{ message.length }} / {{ MAX_LENGTH }}
        </div>
      </div>
      <div class="create-message">
        <label for="create-message-field">Input chat message</label>
        <label class="file-upload" [class.file-upload__disabled]="attachments.length >= 10">
          <input
            type="file"
            (change)="onFileChange($event)"
            multiple
            [disabled]="attachments.length >= 10"
          />
          <i class="bi-image"></i>
        </label>
        <div class="grow-wrap create-message-field-container">
          <textarea
            rows="1"
            placeholder="Type message"
            [(ngModel)]="message"
            class="create-message-field"
            id="create-message-field"
            (keydown)="onCreateMessageFieldKeyDown($event)"
            (paste)="onCreateMessageFieldPaste($event)"
          ></textarea>
          <div class="grow-wrap-expander">{{ message }}</div>
        </div>
        <button
          class="send-button"
          (click)="onSendButtonClick()"
          [disabled]="!createMessageEnabled"
        >
          <i class="bi-send"></i>
        </button>
      </div>
    </footer>
  </section>
</div>

<app-react-picker></app-react-picker>
<app-message-context-menu></app-message-context-menu>
