import { Component, EventEmitter, Input, Output } from '@angular/core'
import { FileResolver } from '../../../file/infrastructure/file.resolver'
import { Room } from '../../domain/room/room'
import { IMAGE_SHEEP } from '../../../IMAGE'

@Component({
  selector: 'app-room-presentation',
  templateUrl: './room-presentation.component.html',
  styleUrls: ['./room-presentation.component.scss'],
})
export class RoomPresentationComponent {
  @Input() public room!: Room
  @Input() public isSelected = false

  @Output() public selected = new EventEmitter<undefined>()

  public readonly defaultRoomImage = IMAGE_SHEEP

  public constructor(private fileResolver: FileResolver) {}

  public resolveUrl(path: string): string {
    return this.fileResolver.resolve(path)
  }

  public onClick() {
    this.selected.emit()
  }
}
