import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core'
import { UserMessage } from '../../domain/message/user-message'
import { TimeFormat } from '../../../core/format/time-format.service'
import { FileResolver } from '../../../file/infrastructure/file.resolver'
import { IMAGE_SHEEP } from '../../../IMAGE'
import { Attachment } from '../../domain/message/message'
import { REACT_UNKNOWN, REACTS } from '../../domain/message/react'

interface ReactOverview {
  id: string
  emoji: string
  count: number
}

export interface Rectangle {
  left: number
  top: number
  width: number
  height: number
}

@Component({
  selector: 'app-message-presentation',
  templateUrl: './message-presentation.component.html',
  styleUrls: ['./message-presentation.component.scss'],
})
export class MessagePresentationComponent {
  @Input() public message!: UserMessage
  @Input() public shouldShowTime = false
  @Input() public shouldConnectToPreviousMessage = false
  @Input() public shouldConnectToNextMessage = false
  @Input() public shouldDisplayUsernameHeader = true

  @ViewChildren('attachment') public attachmentElements!: QueryList<ElementRef<HTMLImageElement>>
  @ViewChild('reactAction') public reactAction!: ElementRef<HTMLElement>

  @Output() public attachmentLoaded = new EventEmitter<undefined>()
  @Output() public attachmentClicked = new EventEmitter<Attachment>()
  @Output() public reactActionClicked = new EventEmitter<Rectangle>()
  @Output() public contentTapHeld = new EventEmitter<undefined>()

  public readonly defaultUserImage = IMAGE_SHEEP

  public get reacts(): ReactOverview[] {
    const reactOverviews = this.message.message.reacts
      .reduce((reactOverviews, react) => {
        const reactOverview = reactOverviews.get(react.reactId) || {
          id: react.reactId,
          emoji: this.getReactEmojiFromId(react.reactId),
          count: 0,
        }

        reactOverview.count++
        reactOverviews.set(react.reactId, reactOverview)

        return reactOverviews
      }, new Map<string, ReactOverview>())
      .values()

    return [...reactOverviews].sort((a, b) =>
      a.count !== b.count ? b.count - a.count : a.id < b.id ? -1 : 1,
    )
  }

  public constructor(private timeFormat: TimeFormat, private fileResolver: FileResolver) {}

  public formatDate(date: Date): string {
    return this.timeFormat.toHumanReadableDateFormat(date)
  }

  public resolveUrl(path: string): string {
    return this.fileResolver.resolve(path)
  }

  public onAttachmentLoaded() {
    this.attachmentLoaded.emit()
  }

  public onAttachmentClicked(attachment: Attachment) {
    this.attachmentClicked.emit(attachment)
  }

  public onReactActionClick(event: MouseEvent) {
    event.stopPropagation()
    this.reactActionClicked.next(this.elementToRectangle(this.reactAction.nativeElement))
  }

  public getReactEmojiFromId(reactId: string): string {
    return (REACTS.find((react) => react.id === reactId) || REACT_UNKNOWN).emoji
  }

  public elementToRectangle(element: HTMLElement): Rectangle {
    const rectangle = element.getBoundingClientRect()

    return {
      left: rectangle.left + window.scrollX,
      top: rectangle.top + window.scrollY,
      width: rectangle.width,
      height: rectangle.height,
    }
  }

  public onTapHold() {
    this.contentTapHeld.emit()
  }
}
