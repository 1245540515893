<section class="login-container">
  <form class="login" (submit)="onLoginFormSubmit($event)">
    <header class="header">
      <img class="logo" src="assets/chat.svg" />
    </header>

    <div *ngIf="error" class="error">
      {{ error.message }}
    </div>

    <div class="input-group">
      <label>Email</label>
      <input name="login" [(ngModel)]="email" placeholder="Email" />
    </div>

    <div class="input-group">
      <label>Password</label>
      <input name="password" type="password" [(ngModel)]="password" placeholder="Password" />
    </div>

    <div class="input-group">
      <input class="submit" type="submit" value="Login" />
    </div>

    <div class="links">
      <a [routerLink]="['/register']">Register</a>
      <span>|</span>
      <a href="#" (click)="onForgotPasswordClick($event)">Forgot password</a>
    </div>
  </form>
</section>
