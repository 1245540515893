import { Component } from '@angular/core'
import { MessageContextMenuService } from './message-context-menu.service'
import { merge } from 'rxjs'
import { UserMessage } from '../../../domain/message/user-message'
import { React, REACTS } from '../../../domain/message/react'

@Component({
  selector: 'app-message-context-menu',
  templateUrl: './message-context-menu.component.html',
  styleUrls: ['./message-context-menu.component.scss'],
})
export class MessageContextMenuComponent {
  public reacts = REACTS
  public message?: UserMessage
  public selectedReact?: React
  public show = false
  public visible = false

  public constructor(private service: MessageContextMenuService) {
    merge(service.show$, service.hide$).subscribe((show) => {
      this.visible = show !== undefined

      if (show !== undefined) {
        this.message = show.message
        this.selectedReact = show.selectedReact
        this.show = this.visible
      } else {
        setTimeout(() => {
          this.message = undefined
          this.selectedReact = undefined
          this.show = this.visible
        }, 200)
      }
    })
  }

  public onReactClick(react: React) {
    if (this.selectedReact?.id === react.id) {
      this.service.action({ action: 'unreact' })
    } else {
      this.service.action({ action: 'react', react })
    }
  }

  public async onCopyClick() {
    if (navigator && navigator.clipboard && this.message) {
      await navigator.clipboard.writeText(this.message.message.text)
    }
  }

  public onBackgroundClick() {
    this.service.hide()
  }

  public onContextMenu(event: MouseEvent) {
    event.preventDefault()
    event.stopPropagation()
  }
}
