import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'
import { React } from '../../domain/message/react'

export interface SummonData {
  selectedReactId?: string
  left: number
  top: number
  width: number
  height: number
}

@Injectable({
  providedIn: 'root',
})
export class ReactPickerService {
  public readonly $show = new Subject<SummonData>()
  public readonly $hide = new Subject<undefined>()
  public readonly $reacts = new Subject<React | undefined>()

  public show(
    selectedReactId: string | undefined,
    left: number,
    top: number,
    width: number,
    height: number,
  ) {
    this.$show.next({ selectedReactId, left, top, width, height })
  }

  public hide() {
    this.$hide.next(undefined)
  }

  public react(react?: React) {
    this.$reacts.next(react)
  }
}
