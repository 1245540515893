import { Room } from './room'
import { USER_DUMMY_ME, USER_DUMMY_OTHER } from '../../../user/domain/user/USERS'
import { IMAGE_SHEEP } from '../../../IMAGE'

export const ROOMS = [
  new Room(
    '00000000-0000-0000-0000-000000000000',
    '',
    new Date(),
    [USER_DUMMY_ME.id, USER_DUMMY_OTHER.id],
    IMAGE_SHEEP,
  ),
]
