<div
  #reactPicker
  [ngClass]="{
    'react-picker': true,
    'react-picker__visible': visible,
    'react-picker__selected': selectedReactId !== undefined
  }"
  [style.top]="top"
  [style.left]="left"
>
  <div class="react-container">
    <a
      *ngFor="let react of reacts"
      [ngClass]="{
        react: true,
        react__selected: react.id === selectedReactId
      }"
      (click)="onReactClick(react)"
    >
      <div class="emoji-wrapper" [innerHTML]="react.emoji | emoji"></div>
    </a>
  </div>
</div>
