import { Injectable } from '@angular/core'
import { environment } from '../../../environments/environment'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { Message, PrimitiveMessage, primitiveMessageToMessage } from '../domain/message/message'
import { map } from 'rxjs/operators'
import {
  CreateMessageRequestBodyAttachment,
  MessageService,
} from '../domain/message/message.service'

@Injectable({
  providedIn: 'root',
})
export class HttpMessageService implements MessageService {
  private readonly baseUrl = environment.apiUrl

  public constructor(private http: HttpClient) {}

  public getMessages(roomId: string, before?: string): Observable<Message[]> {
    const options = before ? { params: { before: before } } : {}
    return this.http
      .get<PrimitiveMessage[]>(`${this.baseUrl}/room/${roomId}/message`, options)
      .pipe(map((messages) => messages.map(primitiveMessageToMessage)))
  }

  public sendMessage(
    roomId: string,
    text: string,
    attachments: CreateMessageRequestBodyAttachment[] = [],
  ) {
    return this.http.post(`${this.baseUrl}/room/${roomId}/message`, {
      text,
      attachments,
    })
  }

  public react(messageId: string, reactId: string): Observable<object> {
    return this.http.put(`${this.baseUrl}/message/${messageId}/react`, {
      reactId,
    })
  }

  public unreact(messageId: string): Observable<object> {
    return this.http.delete(`${this.baseUrl}/message/${messageId}/react`)
  }
}
