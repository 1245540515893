import { Injectable } from '@angular/core'
import { formatDate } from '@angular/common'

@Injectable({
  providedIn: 'root',
})
export class TimeFormat {
  public toHumanReadableDateFormat(date: Date): string {
    const today = new Date()

    if (this.isSameDay(today, date)) {
      return this.toTimeFormat(date)
    }

    const aWeekAgo = new Date()
    aWeekAgo.setDate(aWeekAgo.getDate() - 6)
    aWeekAgo.setHours(0, 0, 0, 0)

    if (aWeekAgo.getTime() < date.getTime()) {
      return this.format(date, 'ccc H:mm')
    }

    return this.format(date, 'd. MMM yy H:mm')
  }

  public toTimeFormat(date: Date): string {
    return this.format(date, 'H:mm')
  }

  public format(date: Date, format: string) {
    return formatDate(date, format, 'en-US')
  }

  private isSameDay(a: Date, b: Date): boolean {
    return (
      a.getDate() === b.getDate() &&
      a.getMonth() === b.getMonth() &&
      a.getFullYear() === b.getFullYear()
    )
  }
}
