import { Injectable } from '@angular/core'
import { concat, EMPTY, filter, merge, Observable, of } from 'rxjs'
import { Attachment, Message, PrimitiveMessage } from '../domain/message/message'
import {
  CreateMessageRequestBodyAttachment,
  MessageService,
} from '../domain/message/message.service'
import { HttpMessageService } from './http-message.service'
import { map, take, tap } from 'rxjs/operators'
import { CachedMessage } from '../application/cached.message'
import { SocketService } from '../../core/socket/socket.service'
import { UserReact } from '../domain/message/user-react'

@Injectable({
  providedIn: 'root',
})
export class CachedMessageService implements MessageService {
  public constructor(
    private messageService: HttpMessageService,
    private socketService: SocketService,
  ) {
    this.socketService.events$
      .pipe(
        filter(({ name }) => name === 'room:message-sent'),
        map(({ args: [roomId, primitiveMessage] }) => ({
          roomId,
          message: this.primitiveToCachedMessage(primitiveMessage as PrimitiveMessage),
        })),
      )
      .subscribe(({ roomId, message }) => this.addCachedMessage(roomId as string, message))
  }

  public getMessages(roomId: string, before?: string): Observable<Message[]> {
    if (!before) {
      return merge(
        this.getCachedMessages(roomId),
        this.cacheMessages(roomId, this.messageService.getMessages(roomId)),
      )
    }

    return this.messageService.getMessages(roomId, before)
  }

  public sendMessage(
    roomId: string,
    text: string,
    attachments: CreateMessageRequestBodyAttachment[] = [],
  ) {
    return this.messageService.sendMessage(roomId, text, attachments)
  }

  public react(messageId: string, reactId: string): Observable<object> {
    return this.messageService.react(messageId, reactId)
  }

  public unreact(messageId: string): Observable<object> {
    return this.messageService.unreact(messageId)
  }

  private getCachedMessages(roomId: string): Observable<CachedMessage[]> {
    const key = this.getMessagesCacheKey(roomId)
    const messagesJson = localStorage.getItem(key)

    if (messagesJson !== null) {
      return of(
        (JSON.parse(messagesJson) as PrimitiveMessage[]).map((message) =>
          this.primitiveToCachedMessage(message),
        ),
      )
    }

    return EMPTY
  }

  private getMessagesCacheKey(roomId: string) {
    return `MessageService.getMessages(${roomId})`
  }

  private cacheMessages(roomId: string, messages: Observable<Message[]>): Observable<Message[]> {
    return messages.pipe(
      tap((messages) => {
        const primitiveMessages = messages.map((message) => this.messageToPrimitive(message))

        localStorage.setItem(this.getMessagesCacheKey(roomId), JSON.stringify(primitiveMessages))
      }),
    )
  }

  private primitiveToCachedMessage(message: PrimitiveMessage): CachedMessage {
    return new CachedMessage(
      message.id,
      message.authorId,
      message.text,
      new Date(message.createdAt),
      message.attachments.map(
        ({ id, path, createdAt }) => new Attachment(id, path, new Date(createdAt)),
      ),
      (message.reacts || []).map(({ userId, reactId }) => new UserReact(userId, reactId)),
    )
  }

  private messageToPrimitive(message: Message): PrimitiveMessage {
    return {
      ...message,
      createdAt: message.createdAt.toJSON(),
      attachments: message.attachments.map((attachment) => ({
        ...attachment,
        createdAt: attachment.createdAt.toJSON(),
      })),
      reacts: message.reacts,
    }
  }

  private addCachedMessage(roomId: string, message: CachedMessage) {
    const cachedMessages$ = concat(this.getCachedMessages(roomId), of([] as CachedMessage[])).pipe(
      take(1),
      map((messages) => [...messages, message].slice(-30)),
    )

    this.cacheMessages(roomId, cachedMessages$)

    cachedMessages$.subscribe()
  }
}
