<div class="lightbox-background" (click)="onBackgroundClick()">
  <div class="lightbox">
    <div class="image-container" [style.aspect-ratio]="aspectRatio">
      <div class="actions">
        <a class="action" (click)="onCloseActionClick($event)">
          <i class="bi-x-lg"></i>
        </a>
      </div>
      <a class="previous" (click)="onPreviousClick($event)">
        <i class="bi-arrow-left"></i>
      </a>
      <a class="next" (click)="onNextClick($event)">
        <i class="bi-arrow-right"></i>
      </a>
      <img #img [src]="image.path" [alt]="image.description || ''" (load)="onImageLoad($event)" />
    </div>
    <div class="description">
      <p>{{ counter }}</p>
    </div>
  </div>
</div>
