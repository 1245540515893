import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

interface SocketEvent {
  name: string
  args: (object | string | number)[]
}

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  public events$ = new Subject<SocketEvent>()
}
