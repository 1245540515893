<header class="time-header" *ngIf="shouldShowTime">
  {{ formatDate(message.message.createdAt) }}
</header>

<div
  [ngClass]="{
    'message': true,
    'message__my': message.isMy,
    'message__same-sender-previous': shouldConnectToPreviousMessage,
    'message__same-sender-next': shouldConnectToNextMessage,
    'message__has-username': shouldDisplayUsernameHeader,
    'message__has-text': message.message.text.length > 0,
    'message__has-attachments': message.message.attachments.length > 0,
    'message__has-reacts': message.message.reacts.length > 0
  }"
  [title]="formatDate(message.message.createdAt)"
  [attr.data-id]="message.message.id"
>
  <aside class="user">
    <img class="profile-image" [src]="message.user.image ? message.user.image : defaultUserImage" />
  </aside>

  <div class="content" (appTapHold)="onTapHold()">
    <header class="username" *ngIf="shouldDisplayUsernameHeader">
      {{ message.user.displayName }}
    </header>

    <article
      *ngIf="message.message.text.length > 0"
      class="text"
      [innerHTML]="message.message.text | emoji"
    ></article>

    <div
      *ngIf="message.message.attachments.length > 0"
      class="attachments"
      [attr.data-count]="message.message.attachments.length"
      [attr.data-count-modulo-3]="message.message.attachments.length % 3"
    >
      <a
        *ngFor="let attachment of message.message.attachments"
        class="attachment"
        (click)="onAttachmentClicked(attachment)"
      >
        <img [src]="resolveUrl(attachment.path)" (load)="onAttachmentLoaded()" alt="attachment" />
      </a>
    </div>
    <div *ngIf="message.message.reacts.length > 0" class="reacts">
      <a *ngFor="let react of reacts" class="react">
        <span [innerHTML]="react.emoji | emoji"></span>
        <span class="count" *ngIf="react.count > 1">{{ react.count }}</span>
      </a>
    </div>
  </div>

  <div class="actions">
    <div #reactAction class="action" (click)="onReactActionClick($event)">
      <i class="bi-emoji-laughing"></i>
    </div>
  </div>
</div>
