<form class="register" (submit)="onSubmit($event)">
  <div class="input-group">
    <label>Email</label>
    <input name="email" [(ngModel)]="email" disabled />
  </div>

  <div class="input-group">
    <label>Display name</label>
    <input name="displayName" [(ngModel)]="displayName" placeholder="Display name" />
  </div>

  <div class="input-group">
    <label>Password</label>
    <input name="password" type="password" [(ngModel)]="password" placeholder="Password" />
  </div>

  <div class="input-group">
    <input class="submit" type="submit" value="Register" [disabled]="!enabled" />
  </div>

  <div class="links">
    <a [routerLink]="['/login']">Login</a>
  </div>
</form>
